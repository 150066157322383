import * as React from "react";
import Store from "../store/store";
import { observer } from "mobx-react";
import axios from "axios";
import { IDoc } from "../ListeDocuments/interfaces/IDoc";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Modal from "@material-ui/core/Modal/Modal";
import { IDBWorkflow } from "../Workflow/interfaces/IWorkflow";
import Spinner from "../Spinner/Spinner";
import TreeMenu from "react-simple-tree-menu";
import { withRouter, RouteComponentProps } from "react-router";
import { Icon as IconFabric } from 'office-ui-fabric-react/lib/Icon';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { Spinner as SpinnerFabric, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';


const modal = require("../../styles/styles.module.scss");

const styles = require("./styles/docassocie.scss");

interface Props {
  selectedDoc: IDoc;
  project: IDBWorkflow;
  showPJOnClick: (doc: any, isDocPrincipal: boolean) => void;
}
interface State {
  modelSuggestionsState?: any[];
  idsDoc?: any[];
  test?: any;
  isPassedInTheFunction?: boolean;
  reload?: boolean;
  getDocAssocieById?: () => void;
  testOfValue?: any;
  files?: any[];
  urlSharepoint?: string;
  documentAssociated?: any[];
  webRacine?: string;
  allSitesSharepoint?: any[];
  formatSitesSharepoint?: any[];
  treeModeles?: any[];
  value?: number;
  docAssoDeleting?: any;
  loadingDocs: boolean;
  loadingButton: boolean;
  modalVisible: boolean;
  selectedFiles: any;
  loadingDeleteButton: boolean;
  loadingResponseButton: boolean;
  modalResponseVisible: boolean;
  docSelectedAsResponse?: any;
  responseModelId: number;
  selectedResponseModel: { name?: string, guid?: string, id?: number };
  loadingResponseModal: boolean;
}

const docAssociated: any[] = [];
const pass: boolean = false;
const count: number = 0;

@observer
class DocAssocie extends React.Component<RouteComponentProps<{}> & any, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      modelSuggestionsState: docAssociated,
      idsDoc: [],
      test: 2,
      isPassedInTheFunction: false,
      reload: false,
      testOfValue: "file", // changer apres version 0.0.13
      files: [],
      documentAssociated: [],
      webRacine: "",
      allSitesSharepoint: [],
      formatSitesSharepoint: [],
      treeModeles: [],
      value: 0,
      loadingDocs: false,
      loadingButton: false,
      docAssoDeleting: null,
      modalVisible: false,
      modalResponseVisible: false,
      selectedFiles: null,
      loadingDeleteButton: false,
      loadingResponseButton: false,
      responseModelId: 0,
      selectedResponseModel: {},
      loadingResponseModal: true
    };
  }

  componentDidMount() {
    if (this.props.selectedDoc.id != undefined && this.props.selectedDoc.id != null && this.props.project.id != undefined && this.props.project.id != null) {
      this.getDocAssociatedById();
    }
  }

  // public onChangeSelect = (name, option) => {
  //   if (name == "editStepModel") {
  //     if (option.length > 0) {
  //       const tempArray = option.map((option) => option.value);
  //       this.setState({ idsDoc: tempArray });
  //     } else {
  //       this.setState({ idsDoc: [] });
  //     }
  //   }
  // };

  public getDocAssociatedById = () => {
    this.setState({ loadingDocs: true }, () => {

      if (this.props.selectedDoc.dataSharepoint != undefined && this.props.selectedDoc.dataSharepoint != null && this.props.selectedDoc.isGRC == false && this.props.selectedDoc.isForm == false) {
        let folderServerRelativeUrl: string = "";
        let listName: string = "";
        let uniqueIdDocOrigine: string = "";
        if ((this.props.project.state == "Terminé" || this.props.project.state == "Refusé") && this.props.selectedDoc.dataSharepoint.finishDoc != undefined && this.props.selectedDoc.dataSharepoint.finishDoc != null && this.props.selectedDoc.dataSharepoint.finishDoc.folderNameContainer != null && this.props.selectedDoc.dataSharepoint.finishDoc.folderNameContainer != undefined) {
          folderServerRelativeUrl = this.props.selectedDoc.dataSharepoint.finishDoc.folderServerRelativeUrl;
          listName = this.props.selectedDoc.dataSharepoint.finishDoc.listNameContainer;
          uniqueIdDocOrigine = this.props.selectedDoc.dataSharepoint.finishDoc.uniqueId;
        } else if (this.props.selectedDoc.dataSharepoint.copyDocEdit != undefined && this.props.selectedDoc.dataSharepoint.copyDocEdit != null && this.props.selectedDoc.dataSharepoint.copyDocEdit.folderNameContainer != null && this.props.selectedDoc.dataSharepoint.copyDocEdit.folderNameContainer != undefined) {
          folderServerRelativeUrl = this.props.selectedDoc.dataSharepoint.copyDocEdit.folderServerRelativeUrl;
          listName = this.props.selectedDoc.dataSharepoint.copyDocEdit.listNameContainer;
          uniqueIdDocOrigine = this.props.selectedDoc.dataSharepoint.copyDocEdit.uniqueId;
        }

        axios.get(Store.wsPath + "/1/Documents/getDocAssociatedById", {
          params: {
            folderServerRelativeUrl: folderServerRelativeUrl,
            listName: listName,
          },
        }).then((response) => {
          if (response.data != "Le dossier n existe pas") {
            // Si besoin d'afficher le doc principal (la copie) il suffit de supprimer les deux ligne si dessous
            const allDocAssocier = response.data.filter(
              (doc) => "{" + doc.File.UniqueId + "}" != uniqueIdDocOrigine
            );
            // Store.docsAssociated = allDocAssocier;
            Store.reloadTab = false;
            if (this.props.selectedDoc.responseFileLink != null && Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.WfStep.isResponseStep == true) {
              if (response.data.filter(d => this.props.selectedDoc.responseFileLink.indexOf(d.File.Name) != -1).length > 0) {
                const responseDoc = response.data.filter(d => this.props.selectedDoc.responseFileLink.indexOf(d.File.Name) != -1)[0];
                this.props.showPJOnClick(responseDoc, false, true);
              }

            }
            this.setState({ documentAssociated: allDocAssocier, loadingDocs: false });
          } else {
            // Store.docsAssociated = [];
            this.setState({ documentAssociated: [], loadingDocs: false });
          }
        }).catch(error => {
          console.error("Error 'getDocAssociatedById' : ", error);
          this.setState({ loadingDocs: false });
        });
      }
      else if (this.props.selectedDoc.dataSharepoint != undefined && this.props.selectedDoc.dataSharepoint != null && (this.props.selectedDoc.isGRC == true || this.props.selectedDoc.isForm == true)) {

        const siteUrl: string = this.props.selectedDoc.dataSharepoint.site;
        const listId: string = this.props.selectedDoc.dataSharepoint.idList;
        const itemId: number = this.props.selectedDoc.dataSharepoint.Id;

        axios.get(Store.wsPath + "/1/Documents/getDocAssociatedFormDoc", {
          params: {
            siteUrl: siteUrl,
            listId: listId,
            itemId: itemId
          }
        }).then((response) => {
          // Store.docsAssociated = response.data;
          Store.reloadTab = false;
          if (this.props.selectedDoc.responseFileLink != null && Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.WfStep.isResponseStep == true) {
            if (response.data.filter(d => this.props.selectedDoc.responseFileLink.indexOf(d.File.Name) != -1).length > 0) {
              const responseDoc = response.data.filter(d => this.props.selectedDoc.responseFileLink.indexOf(d.File.Name) != -1)[0];
              this.props.showPJOnClick(responseDoc, false, true);
            }

          }
          this.setState({ documentAssociated: response.data, loadingDocs: false });
        }).catch(error => {
          console.error("Error 'getDocAssociatedById' : ", error);
          this.setState({ loadingDocs: false });
        });
      }
      else {
        this.setState({ loadingDocs: false });
      }
    });
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.selectedDoc.id && nextProps.selectedDoc.id && nextProps.project.id != this.props.project.id) {
      this.getDocAssociatedById();
    }
  }

  public validFormDocAssociated = () => {
    this.setState({ loadingButton: true }, () => {
      const formData = new FormData();

      this.state.files.forEach(file => {
        formData.append("file", file);
      });

      formData.append("idDocument", this.props.selectedDoc.id.toString());
      formData.append("idUser", Store.userConnected.id.toString());

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios.post(Store.wsPath + "/1/documents/associatedDocInSharepoint", formData, config).then((response) => {
        if (response.status == 200) {
          Store.snackBar.open = true;
          Store.snackBar.error = false;
          Store.snackBar.message = "Les pièces jointes ont bien été associées";
          this.setState({
            value: this.state.value + 1,
            files: [],
            loadingButton: false
          });
          const input: any = document.getElementById("btn-upload");
          if (input != undefined && input != null) {
            input.value = "";
          }
          this.getDocAssociatedById();
        }
      }).catch(error => {
        Store.snackBar.open = true;
        Store.snackBar.error = true;
        Store.snackBar.message = "Une erreur est survenue lors de l'ajout des pièces jointes";
        this.setState({ loadingButton: false });
      })
    })
  }

  public deleteDocAssoModal = (doc: any) => (e: any) => {
    this.setState({
      docAssoDeleting: doc,
    });
    this.modalOpen();
  };
  public selectDocAsResponseModal = (doc: any) => (e: any) => {
    this.setState({
      docSelectedAsResponse: doc,
    });
    this.modalResponseOpen();
  };

  public deleteDocAsso = () => {
    this.setState({ loadingDeleteButton: true });
    if (this.state.docAssoDeleting != undefined && this.state.docAssoDeleting != null) {
      axios.post(Store.wsPath + "/1/documents/deleteAssociatedDocInSharepoint", {
        docAsso: this.state.docAssoDeleting,
        idUser: Store.userConnected.id,
        idDoc: this.props.selectedDoc.id,
        isGRC: this.props.selectedDoc.isGRC,
        isForm: this.props.selectedDoc.isForm,
        formType: this.props.selectedDoc.formType
      }).then((response) => {
        this.modalClose();
        const allDocAssocier: any[] = this.state.documentAssociated.filter(d => d.Id != this.state.docAssoDeleting.Id || d.ServerRelativeUrl != this.state.docAssoDeleting.ServerRelativeUrl);
        this.setState({
          value: this.state.value + 1,
          docAssoDeleting: {},
          documentAssociated: allDocAssocier,
          loadingDeleteButton: false
        });
        this.props.showPJOnClick(this.props.selectedDoc.dataSharepoint, true, false);
      });
    }
  };

  public onClickModele = (nodes) => {
    if (nodes != undefined && nodes != null) {
      this.setState({ selectedResponseModel: { name: nodes.label, guid: nodes.guid, id: nodes.id } });

    }
  }
  // Fonction qui récupére tous les modéles
  public getResponseModelsWfChild = (idParentModel) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let allModelsWF = [];
        if (idParentModel != null && idParentModel != undefined) {
          const responseAx = await axios.get(Store.wsPath + '/1/workflows/getModelWfChildByParentId', {
            params: {
              idModel: idParentModel
            }
          });
          allModelsWF = responseAx.data;
        } else {
          const responseAx = await axios.get(Store.wsPath + `/1/workflows/getAllModelWf`);
          allModelsWF = responseAx.data;
        }
        const treeModeles = await this.constructTree(allModelsWF, idParentModel);

        this.setState({ treeModeles: treeModeles.treeModeles, loadingResponseModal: false });
        resolve();
      } catch (error) {
        console.error("Error 'getAllModelsWf' : ", error);
        reject("Error 'getAllModelsWf' : " + error);
      }
    });
  }
  public constructTree = async (modeles: any[], idParent) => {
    const modelesParent: any[] = modeles.filter(modele => modele.idParent == idParent);
    const testResult: any = [];
    if (modelesParent.length > 0) {
      for (const modeleParent of modelesParent) {
        const children = await this.getChildren(modeleParent, modeles);
        testResult.push({ key: modeleParent.id.toString(), id: modeleParent.id, label: modeleParent.name, nodes: children, isGRC: modeleParent.isGRC, isForm: modeleParent.isForm, formType: modeleParent.formType, guid: modeleParent.guid });
      }
      return { treeModeles: testResult, allModelsList: modeles };
    }
    else {
      testResult.push({ key: "Pas de modèle pour le moment", label: "Pas de modèle pour le moment", name: "Pas de modèle pour le moment", id: "noModel" });
      return { treeModeles: testResult, allModelsList: [] };
    }
  }
  // Fonction de récurssivitée pour construire l'arbre des modèles
  public getChildren = async (modeleParent, allModeles: any[]) => {
    const treeChildren: any[] = [];
    const children: any[] = allModeles.filter(modele => modele.idParent == modeleParent.id);
    for (const child of children) {
      const usersDirectReports = await this.getChildren(child, allModeles);

      usersDirectReports ? treeChildren.push({ key: child.id.toString(), id: child.id, label: child.name, nodes: usersDirectReports, isGRC: child.isGRC, isForm: child.isform, formType: child.formType, guid: child.guid }) :
        treeChildren.push({ title: child });
    }
    return treeChildren;
  }

  public onValidateResponse = (doc) => event => {
    this.setState({ loadingResponseButton: true })
    const urlSharepoint = Store.userConnected.siteUrl.endsWith("/") ? Store.userConnected.siteUrl.substring(0, Store.userConnected.siteUrl.length - 1) : Store.userConnected.siteUrl
    const responseFileLink = urlSharepoint + doc.File.ServerRelativeUrl;
    axios.post(Store.wsPath + '/1/workflows/orienteProject', {
      idModel: this.state.selectedResponseModel.id,
      idUserConnected: Store.userConnected.id,
      parentOrientationStepRank: Store.myStepTaskSelectionned.WfStep.rankStep,
      idParentStep: Store.myStepTaskSelectionned.WfStep.id,
      modeSharepoint: Store.modeSharepoint,
      responseFileLink: responseFileLink
    }).then((response) => {
      this.modalResponseClose()
      if (response.data.stayOnSameProject == true) {
        this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
      } else {
        this.props.history.push("/refresh?redir=document");
      }
    }).catch(error => {
      console.error("Error 'onValidateOrientation' : ", error);
      this.modalResponseClose()
      Store.snackBar = {
        message: "Une erreur est survenue lors de la sélection de votre réponse, réessayez",
        error: true,
        open: true
      }
    })
  }
  modalClose = () => {
    this.setState({ modalVisible: false });
    // Store.modal.visible = false;
  };

  modalOpen = () => {
    this.setState({ modalVisible: true });
    // Store.modal.visible = true;
  };
  modalResponseClose = () => {
    this.setState({
      modalResponseVisible: false,
      value: this.state.value + 1,
      docSelectedAsResponse: {},
      selectedResponseModel: {},
      loadingResponseButton: false
    });
    // Store.modal.visible = false;
  };

  modalResponseOpen = () => {
    this.setState({ modalResponseVisible: true, loadingResponseModal: true });
    this.getResponseModelsWfChild(Store.myStepTaskSelectionned.WfStep.responseModelParentId)
    // Store.modal.visible = true;
  };

  isInDocAssociated = (filesUpload: any[]) => {
    for (let i = 0; i < filesUpload.length; i++) {
      const file = filesUpload[i];
      if (this.state.documentAssociated.filter(docAsso => docAsso.File.Name === file.name).length > 0) {
        return true;
      }
    };
    return false;
  }

  isSameNameOfDocPrincipal = (docPrincipalFileName: string, uploadFiles: any[]) => {
    for (let i = 0; i < uploadFiles.length; i++) {
      const file = uploadFiles[i];
      const filenameSplit: string[] = file.name.split('.');
      filenameSplit.pop();
      const filename: string = filenameSplit.join('.');
      if (docPrincipalFileName === filename) {
        return true;
      }
    }
    return false;
  }

  selectFile = (event) => {
    let error = document.getElementById('error-file');
    error.setAttribute('style', 'display: none');
    const filesUpload = Array.from(event.target.files);

    // On check si un document à le même nom que le document principal, si c'est le cas on mets une erreur sinon on continue
    if (this.isSameNameOfDocPrincipal(this.props.selectedDoc.title, filesUpload) != true) {
      if (!this.isInDocAssociated(filesUpload)) {
        this.setState({
          files: filesUpload
        });
      } else {
        error.innerHTML = filesUpload.length == 1 ? "Un document associé porte déjà le même nom." : "L'un des documents joints porte le même nom qu'un document associé, veuillez le renommer ou le supprimer des documents associés.";
        const input: any = document.getElementById("btn-upload");
        if (input != undefined && input != null) {
          input.value = "";
        }
        error.setAttribute('style', 'display: block');
      }
    } else {
      error.innerHTML = filesUpload.length == 1 ? "Votre document ne peut pas porter le même nom que le document principal." : "L'un des documents porte le même nom que le document principal, veuillez le renommer avant de l'associer";
      const input: any = document.getElementById("btn-upload");
      if (input != undefined && input != null) {
        input.value = "";
      }
      error.setAttribute('style', 'display: block');
    }
  };

  deleteSelectedFile = () => {
    this.setState({
      files: []
    });
    const input: any = document.getElementById("btn-upload");
    if (input != undefined && input != null) {
      input.value = "";
    }
  };
  downloadAssoDoc = (doc: IDoc) => {
    const urlSharepoint = Store.userConnected.siteUrl.endsWith("/") ? Store.userConnected.siteUrl.substring(0, Store.userConnected.siteUrl.length - 1) : Store.userConnected.siteUrl
    return (urlSharepoint + doc.File.ServerRelativeUrl + "?download=1")
  }
  downloadMainDoc = (doc: IDoc, project: IDBWorkflow) => {
    if (Store.userConnected.siteUrl != undefined && Store.userConnected.siteUrl != null) {
      const urlSharepoint = Store.userConnected.siteUrl.endsWith("/") ? Store.userConnected.siteUrl.substring(0, Store.userConnected.siteUrl.length - 1) : Store.userConnected.siteUrl
      let urlDoc: string = doc.dataSharepoint.fileRef;
      if ((project.state == "Terminé" || project.state == "Refusé") && doc.dataSharepoint.finishDoc != undefined && doc.dataSharepoint.finishDoc != null) {
        urlDoc = doc.dataSharepoint.finishDoc.editFileRef;
      }
      else if (doc.dataSharepoint.copyDocEdit != undefined && doc.dataSharepoint.copyDocEdit != null) {
        urlDoc = doc.dataSharepoint.copyDocEdit.editFileRef;
      }

      return (urlSharepoint + urlDoc + "?download=1");
    }
  }

  renderDocPrincipal = (doc: IDoc, project: IDBWorkflow) => {
    if (Store.modeSharepoint == true && doc.isGRC == false && doc.isForm == false && doc.dataSharepoint != undefined && doc.dataSharepoint != null) {
      let extentionPrincipalDoc: string = "";
      let temp = doc.dataSharepoint.copyDocEdit != undefined && doc.dataSharepoint.copyDocEdit.Name != undefined ? doc.dataSharepoint.copyDocEdit.Name.split('.') : doc.dataSharepoint.FileLeafRef.split('.');
      extentionPrincipalDoc = temp[temp.length - 1];

      let fileName = "";

      // Si le projet est terminé on prend le doc d'archive
      if ((project.state == "Terminé" || project.state == "Refusé") && doc.dataSharepoint.finishDoc != undefined) {
        fileName = doc.dataSharepoint.finishDoc.Name;
      }
      else if (doc.dataSharepoint.copyDocEdit != undefined && doc.dataSharepoint.copyDocEdit != null) {
        fileName = doc.dataSharepoint.copyDocEdit.Name;
      }
      else {
        fileName = doc.fileName != undefined && doc.fileName != null ? doc.fileName : doc.title;
      }

      return (
        <div title={fileName} style={{display: "flex", alignItems: "center"}}>
          <IconFabric className={styles.extentions} {...getFileTypeIconProps({ extension: extentionPrincipalDoc, size: 24, imageFileType: 'svg' })} />
          {fileName}
          <a href={this.downloadMainDoc(doc, project)}>
            <Icon
              className={styles.downloadMain}
              key={"icon_download" + fileName}
              style={{ cursor: "pointer", marginLeft: "10px" }}
              title="Télécharger"
            >
              download
          </Icon>
          </a>
        </div>
      );
    }
    else if (doc.id != undefined) {
      return (
        <span title={doc.title}>
          {doc.title}
        </span>
      );
    }
    else {
      return (
        <span title={Store.selectedDoc.title}>
          {Store.selectedDoc.title}
        </span>
      );
    }
  }
  public render() {
    let stateFileExist = (this.state.files !== undefined && this.state.files.length > 0);

    let nbDocAssoc = this.state.documentAssociated.length;

    let isInTask = false;
    this.props.project.WfSteps.forEach(step => {
      if (step.WfTasks.length > 0) {
        step.WfTasks.forEach(task => {
          if (task.typeTask != undefined && task.typeTask != null && task.typeTask.value == 1) {
            if (task.userIdx == Store.userConnected.id) {
              isInTask = true;
            } else if (task.User != undefined && task.User != null && task.User.isGroup) {
              if (task.editedGroup != undefined && task.editedGroup != null) {
                if (task.editedGroup.find(userOfGroup => userOfGroup === Store.userConnected.id) != undefined) {
                  isInTask = true;
                };
              } else if (task.User.usersOfGroup != undefined && task.User.usersOfGroup != null) {
                if (task.User.usersOfGroup.find(userOfGroup => userOfGroup === Store.userConnected.id) != undefined) {
                  isInTask = true;
                };
              }
            }
          }
        })
      }
    })
    const project:IDBWorkflow = this.props.project;
    const addAndDeletePj: boolean = this.props.project.state != "Terminé" && this.props.project.state != "Refusé" && (isInTask || Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || project.Document.gestionnaires.find(g => g.id == Store.userConnected.id ) != undefined  );
    return (
      <div className={styles.circuitPanel} >
        <form
          id="formDocAss"
          encType="multipart/form-data"
        // onSubmit={this.validFormDocAssociated.bind(this)}
        >
          <div className={styles.titleContainer} style={{ display: "flex" }}>
            <div className={styles.titleElement}> Document{(nbDocAssoc > 0) ? 's' : ''}</div>
            {
              addAndDeletePj ?
                <div className={styles.addFileButton}>
                  <label htmlFor="btn-upload">
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={this.selectFile}
                      multiple
                    />
                    <Button
                      className={styles.addButton}
                      variant="outlined"
                      component="span" >
                      <Icon>add</Icon>
                    </Button>
                  </label>
                </div>
                :
                ""
            }
          </div>
          <div className={styles.expansionPanelDetailMUi} >
            <div>
              <div style={{ position: "relative" }}>
                <div className={styles.contentTextfield}>
                  {this.props.project.state != "Terminé" && this.props.project.state != "Refusé" ?
                    <div>
                      <div className={styles.select}>
                        {/* <RadioGroup
                                    name={"Importer un document associé"}
                                    defaultValue="file"
                                    onChange={this.onChange("typeGestionDocAssocie")}
                                >
                                    <div className={styles.styleBtnRadio}>
                                        <FormControlLabel
                                            // checked={this.state.testOfValue}
                                            value="file"
                                            control={<Radio className={styles.radioPadding} color="primary" />}
                                            label="Depuis l'explorateur de fichier"
                                        />0
                                        <FormControlLabel
                                            // checked={this.state.testOfValue}
                                            value="sharepoint"
                                            control={<Radio className={styles.radioPadding} color="primary" />}
                                            label="Depuis la bibliothèque Sharepoint"
                                        />
                                    </div>
                                </RadioGroup> TODO DECOMMENTER QUAND LE CHOIX D'UN DOC ASSOCIE SERA GERE DEPUIS LA BIBLIOETHEQUE SHAREPOINT*/}

                        {/* {this.state.testOfValue == 'sharepoint' ?
                                    <div>
                                        <SelectReact
                                            options={this.state.modelSuggestionsState}
                                            onChange={option => this.onChangeSelect("editStepModel", option ? option : null)}
                                            placeholder="Associer le ou les document(s):"
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            //  className={styles.field}
                                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                                            isClearable
                                            isMulti
                                        //   value={this.state.copieStepModel.idsRole != undefined ? this.updateReactSelect(this.state.copieStepModel.idsRole) : null}
                                        />

                                        <Button
                                            onClick={this.onValidDocumentsAssociated}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            className={styles.btnAssocie}
                                        >
                                            Associer
                                            </Button>
                                    </div>
                                    : <div />} */}

                        {this.state.testOfValue == "file" ? (
                          <div className={styles.encadrUploadAZone}>
                            {/* <UploadZone docassocie = {true}/> */}

                            {/* <div>
                            <Input
                              type="file"
                              title="télécharger un fichier"
                              style={{ zIndex: 0 }}
                              onChange={this.handleChange}
                              id="inputFileDocAsso"
                            />
                            <div>
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                title="Associer"
                                disabled={this.state.loadingButton || this.state.file == undefined}
                              >
                                {
                                  this.state.loadingButton == true ?
                                    <Spinner size={20} />
                                    :
                                    "Associer"
                                }
                              </Button>
                            </div>
                          </div> */}

                          </div>
                        ) : (
                            <div />
                          )}
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
                <div className={styles.newFile}>
                  <p className={styles.errorFile} style={{ display: "none" }} id="error-file"></p>
                  <div className={styles.filesContainer}>
                    {stateFileExist ?
                      this.state.files.map(file => {
                        const temp = file.name.split('.');
                        const extentionNewFile = temp[temp.length - 1];
                        return (
                          <div key={file.name} className={styles.file}>
                            <IconFabric className={styles.extentions} {...getFileTypeIconProps({ extension: extentionNewFile, size: 24, imageFileType: 'svg' })} />
                            <div className={styles.fileName}>
                              {file.name}
                            </div>
                          </div>
                        )
                      })
                      :
                      <React.Fragment />
                    }
                  </div>
                  {stateFileExist ?
                    <div className={styles.fileButtons}>
                      <Button
                        disabled={this.state.loadingButton}
                        className={styles.btnUpload + ' ' + styles.uploadButton}
                        color="primary"
                        variant="contained"
                        onClick={this.validFormDocAssociated}>
                        {
                          this.state.loadingButton === true ?
                            <Spinner size={20} />
                            : 'Associer'
                        }
                      </Button>

                      <Button
                        color="secondary"
                        variant="contained"
                        style={this.state.loadingButton === true ? { display: 'none' } : {}}
                        className={styles.btnUpload}
                        onClick={this.deleteSelectedFile}
                      >
                        Annuler
                      </Button>
                    </div>
                    : ''}
                </div>


                <Modal
                  aria-describedby="simple-modal-description"
                  open={this.state.modalVisible}
                  onClose={this.modalClose}
                  className={modal.modal}
                  disableEnforceFocus={true}
                >
                  <div className={modal.modalContent}>
                    <Typography variant="h5" id="modal-title">
                      Suppression
                </Typography>
                    <br />
                    <Typography variant="subtitle2" id="simple-modal-description">
                      Vous allez supprimer ce document associé, êtes vous sûr(e)s de
                      cette action
                </Typography>
                    <br />
                    <div className={modal.modalButton}>
                      <Button
                        className={modal.button}
                        disabled={this.state.loadingDeleteButton}
                        onClick={this.modalClose}
                        variant="contained"
                        color="secondary"
                      >
                        Annuler
                  </Button>
                      <Button
                        onClick={this.deleteDocAsso}
                        disabled={this.state.loadingDeleteButton}
                        className={modal.button}
                        variant="contained"
                        color="primary"
                      >
                        {
                          this.state.loadingDeleteButton === true ?
                            <Spinner size={20} />
                            : 'Valider'
                        }

                      </Button>
                    </div>
                  </div>
                </Modal>
                <div>
                  <div className={styles.dPrincipalTitle}>Document principal</div>
                  <div className={styles.listing}>
                    <div className={styles.docPrincipal}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.showPJOnClick(this.props.selectedDoc.dataSharepoint, true, false)}
                      >
                        {this.renderDocPrincipal(this.props.selectedDoc, this.props.project)}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className={styles.dSecondaireTitle}>Document{(nbDocAssoc > 1) ? 's' : ''} associé{(nbDocAssoc > 1) ? 's' : ''}</div>
                    <Button
                      className={styles.addButton}
                      style={{ fontSize: "21px" }}
                      variant="outlined"
                      onClick={() => this.getDocAssociatedById()}
                      component="span" >
                      <Icon>refresh</Icon>
                    </Button>
                  </div>
                  {this.state.loadingDocs == true ?
                    <Spinner style={{ padding: "5px" }} size={20} label="Chargement des documents..." labelPlacement="bottom" />
                    :
                    this.state.documentAssociated.length > 0 ? (
                      this.state.documentAssociated.map((doc) => {
                        let extentionDocAsso = doc.File.Name.split('.');
                        extentionDocAsso = extentionDocAsso[extentionDocAsso.length - 1];
                        return (
                          <div key={doc.File.Name} className={styles.listing}>
                            <IconFabric className={styles.extentions} {...getFileTypeIconProps({ extension: extentionDocAsso, size: 24, imageFileType: 'svg' })} />
                            <span
                              style={{ cursor: "pointer" }}
                              title={doc.File.Name}
                              onClick={() => this.props.showPJOnClick(doc, false, false)}
                            >
                              {doc.File.Name}
                            </span>
                            {addAndDeletePj == false || (Store.workflow.Document.responseFileLink != null && Store.workflow.Document.responseFileLink != "" && Store.workflow.Document.responseFileLink.indexOf(doc.File.Name) != -1)? (
                              <React.Fragment></React.Fragment>
                            ) : (
                                <Icon
                                  className={styles.trashcan}
                                  key={"icon_delete" + doc.File.Name}
                                  onClick={this.deleteDocAssoModal(doc)}
                                  style={{ cursor: "pointer", marginLeft: "10px" }}
                                  title="Supprimer"
                                >
                                  close
                                </Icon>
                              )
                            }
                            <a href={this.downloadAssoDoc(doc)}>
                              <Icon
                                className={styles.downloadAsso}
                                key={"icon_download" + doc.File.Name}
                                style={{ cursor: "pointer", marginLeft: "10px" }}
                                title="Télécharger"
                              >
                                download
                                </Icon>
                            </a>
                            {Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.WfStep.isResponse == true && Store.myStepTaskSelectionned.WfStep.state == "En cours" && (Store.workflow.Document.responseFileLink == null || Store.workflow.Document.responseFileLink == "") ?
                              <Icon
                                onClick={this.selectDocAsResponseModal(doc)}
                                className={styles.downloadAsso}
                                key={"icon_send" + doc.File.Name}
                                style={{ cursor: "pointer", marginLeft: "10px" }}
                                title="Sélectionner en tant que réponse"
                              >
                                send
                              </Icon>
                              : <React.Fragment></React.Fragment>
                            }
                            {Store.workflow.Document.responseFileLink != null && Store.workflow.Document.responseFileLink.indexOf(doc.File.Name) != -1 ?
                              <span style={{ marginLeft: "5px" }}>Réponse</span>
                              : <React.Fragment></React.Fragment>
                            }
                          </div>
                        );
                      })
                    ) : (
                        <p className={styles.noDocText}>Pas de documents associés</p>
                      )}
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal
          aria-describedby="simple-modal-description"
          open={this.state.modalResponseVisible}
          onClose={this.modalResponseClose}
          className={modal.modal}
          disableEnforceFocus={true}
        >
          <div className={modal.modalContent}>
            <Typography variant="h5" id="modal-title">
              Sélection de la réponse
            </Typography>
            <br />
            {
              this.state.loadingResponseModal == true ?
                <SpinnerFabric size={SpinnerSize.large} label="Chargement en cours..." labelPosition="bottom" />
                :
                <div>
                  <Typography variant="subtitle2" id="simple-modal-description">
                    Veuillez sélectionner le type de réponse
                </Typography>
                  <TreeMenu
                    data={this.state.treeModeles}
                    onClickItem={this.onClickModele}
                  />
                  <br />
                  <div className={modal.modalButton}>
                    <Button
                      className={modal.button}
                      disabled={this.state.loadingResponseButton}
                      onClick={this.modalResponseClose}
                      variant="contained"
                      color="secondary"
                    >
                      Annuler
                  </Button>
                    <Button
                      onClick={this.onValidateResponse(this.state.docSelectedAsResponse)}
                      disabled={this.state.loadingResponseButton || this.state.selectedResponseModel.name == null || this.state.selectedResponseModel.name == undefined}
                      className={modal.button}
                      variant="contained"
                      color="primary"
                    >
                      {
                        this.state.loadingResponseButton === true ?
                          <Spinner size={20} />
                          : 'Valider'
                      }

                    </Button>
                  </div>
                </div>
            }


          </div>
        </Modal>
      </div>

    );
  }
}
export default withRouter(DocAssocie);
